<template>
  <div class="detail-all">
    <div class="courseGoodsDetailCont">
      <el-row v-if="!$route.query.isNeedBreadcrumb">
        <el-col :span="24">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/courseGoods', query: {layoutType} }">课程</el-breadcrumb-item>
            <el-breadcrumb-item>课程详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <div v-else style="height:30px;"></div>
      <div class="detail-comm">
        <div class="goods-poster">
              <img class="course-bg-img" :src="detailObj.picUrl"/>
              <!-- <p class="fix-img" v-show="detailObj.taskType">{{tagName(detailObj.taskType)}}</p> -->
            </div>
            <div class="base-info-comm">
              <div class="name-tag-w">
                <div class="title-video">
                  <p class="course-name hide">{{detailObj.name}}</p>

                  <ul class="video-course">
                    <li class="item" v-show="detailObj.videoNum"><span class="num">{{detailObj.videoNum}}</span><span class="text">视频</span></li>
                    <li class="bar" v-show="detailObj.videoNum && detailObj.studyHoursNum"></li>
                    <li class="item" v-show="detailObj.studyHoursNum"><span class="num">{{detailObj.studyHoursNum}}</span><span class="text">课时</span></li>
                  </ul>
                </div>
                <!-- <div class="task-label">
                    <p class="label1">
                        所属职业：<span>家政职业经理人</span>
                    </p>
                    <p class="label2">
                        所属工种：<span>家政服务员</span>
                    </p>
                    <p class="label3">
                        技能等级：<span>高级</span>
                    </p>
                </div> -->
                <ul class="tag-list">
                  <li class="tag-item hide"
                    v-for="(tag, index) in detailObj.labelNameList"
                    :key="index"
                  >
                    <!-- <el-tooltip :content="tag" placement="top"> -->
                      <div class="text">{{tag}}</div>
                    <!-- </el-tooltip> -->
                  </li>
                </ul>
                <div class="zhouqi">
                  <p class="sign-time" v-show="detailObj.signUpStartTime && detailObj.signUpEndTime">
                    <span class="label">报名周期：</span>
                    <span class="time">{{formatDate(detailObj.signUpStartTime)}} - {{formatDate(detailObj.signUpEndTime)}}</span>
                  </p>
                  <p class="blank-bar" v-show="detailObj.signUpStartTime && detailObj.signUpEndTime">1</p>
                  <p class="sign-time peixun" v-if="detailObj.trainingStartTime && detailObj.trainingEndTime">
                    <span class="label">培训周期：</span>
                    <span class="time">{{formatDate(detailObj.trainingStartTime)}} - {{formatDate(detailObj.trainingEndTime)}}</span>
                  </p>
                  <p class="sign-time peixun" v-else-if="detailObj.cycleType === 3">
                    <span class="label">培训周期：</span>
                    <span class="time">长期有效</span>
                  </p>
                  <p class="sign-time peixun" v-else-if="detailObj.cycleType === 2">
                    <span class="label">培训周期：</span>
                    <span class="time">随到随学</span>
                  </p>
                </div>
                <div class="zhiye">
                  <p class="sign-time" v-if="detailObj.profession">
                    <span class="label">所属职业：</span>
                    <span class="time">{{detailObj.profession}}</span>
                  </p>
                  <p class="blank-bar" v-show="detailObj.profession && detailObj.workType">1</p>

                  <p class="sign-time" v-if="detailObj.workType">
                    <span class="label">所属工种：</span>
                    <span class="time">{{detailObj.workType}} </span>
                  </p>
                  <p class="blank-bar" v-show="detailObj.skillLevel">1</p>

                  <p class="sign-time" v-if="detailObj.workType && detailObj.skillLevel">
                    <span class="label">技能等级：</span>
                    <span class="time">{{detailObj.skillLevel}}</span>
                  </p>
                </div>
              </div>
              <div class="price-sign-num">
                <div class="price-w">
                  <div v-if="!detailObj.hidePriceTag">
                    <div class="goodsPrice" v-if="detailObj.isFree"><span class="freePrice">{{setFreeString()}}</span></div>
                    <div class="goodsPrice" v-else>
                      <span class="label">商品售价：</span>
                      <span class="normalPrice">¥{{detailObj.salePrice}}</span>
                      <span class="discountPrice" v-if="detailObj.basePrice">¥{{detailObj.basePrice}}</span>
                    </div>
                  </div>
                  <!-- <div>
                    <span class="detail-lasttime-label">有效期:{{time}}</span>
                  </div> -->
                </div>
                 <div class="now-sign-up">
                    <span class="signUpNum" v-if="isJoinPer=='true'">{{detailObj.basicOrderNum?(detailObj.basicOrderNum+detailObj.purchaseNo):detailObj.purchaseNo}}人已报名</span>
                    <el-button :type="isInSignUpCycle?'warning':'info'" v-if="detailObj.status==1&&!detailObj.isOrder" @click="toPay">立即报名</el-button>
                    <div class="quickLearn" v-else-if="detailObj.isOrder">
                      <!-- <el-button @click="toStudy" v-if="isOver === '立即学习' || isOver === '继续学习' && quickLearnInfo" style="width:88px;height:32px;padding:0">任务大纲</el-button> -->
                      <el-button :type="isOverType=='oks'?'warning':'info'" :style="theme === 'minzheng' && isOver === '立即学习' || isOver === '继续学习' ? 'background:#CA4C2D' : isOver === '立即学习' || isOver === '继续学习' ? 'background:#2D67FF' : ''"  @click="quickLearn(1)">{{isOver}}</el-button>
                    </div>
                    <el-button type="info" v-else disabled>{{statusText}}</el-button>
                </div>
              </div>
            </div>
      </div>
      <div class="detail-content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="简介" name="first">
<!--            <div class="detail" v-html="detailObj.desc">-->
              <!-- {{detailObj.desc}} -->
                <div class="introCode">
                    <div class="introCode-item" v-if="detailObj.mallTrainingGoodsDetail&&detailObj.mallTrainingGoodsDetail.applicableObject">
                        <div class="introCode-title" v-if="(detailObj.mallTrainingGoodsDetail.learningObjectives||detailObj.mallTrainingGoodsDetail.courseHighlights||detailObj.mallTrainingGoodsDetail.description)"><div class="in-line"></div><div class="in-title">适用对象</div></div>
                        <div class="introCode-text" v-html="detailObj.mallTrainingGoodsDetail.applicableObject"></div>
                    </div>
                    <div class="introCode-item" v-if="detailObj.mallTrainingGoodsDetail&&detailObj.mallTrainingGoodsDetail.courseHighlights">
                        <div class="introCode-title" v-if="(detailObj.mallTrainingGoodsDetail.learningObjectives||detailObj.mallTrainingGoodsDetail.description||detailObj.mallTrainingGoodsDetail.applicableObject)"><div class="in-line"></div><div class="in-title">课程亮点</div></div>
                        <div class="introCode-text" v-html="detailObj.mallTrainingGoodsDetail.courseHighlights"></div>
                    </div>
                    <div class="introCode-item" v-if="detailObj.mallTrainingGoodsDetail&&detailObj.mallTrainingGoodsDetail.learningObjectives">
                        <div class="introCode-title" v-if="(detailObj.mallTrainingGoodsDetail.description||detailObj.mallTrainingGoodsDetail.courseHighlights||detailObj.mallTrainingGoodsDetail.applicableObject)"><div class="in-line"></div><div class="in-title">培养目标</div></div>
                        <div class="introCode-text" v-html="detailObj.mallTrainingGoodsDetail.learningObjectives"></div>
                    </div>
                    <div class="introCode-item" v-if="detailObj.mallTrainingGoodsDetail&&detailObj.mallTrainingGoodsDetail.description">
                        <div class="introCode-title" v-if="(detailObj.mallTrainingGoodsDetail.learningObjectives||detailObj.mallTrainingGoodsDetail.courseHighlights||detailObj.mallTrainingGoodsDetail.applicableObject)"><div class="in-line"></div><div class="in-title">内容介绍</div></div>
                        <div class="introCode-text" v-html="detailObj.mallTrainingGoodsDetail.description"></div>
                    </div>
                </div>

          </el-tab-pane>
          <el-tab-pane label="目录" name="second" v-if="isTab=='true'&&detailObj.goodsType!=3">
            <div class="list" v-if="Object.keys(catalogList).length>0">

              <TaskItem  :taskCatalogList="catalogList" :goodsId="goodsId" :isOverType="isOverType" :detailObj="detailObj"></TaskItem>
            </div>
            <div v-else class="noData">
              <img src="../../../assets/images/common/nodata.png" width="242"/>
              <div>课程目录正在建设中，敬请期待～</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <facedialog v-if="verification" :confirmdialog="confirmdialog" :address="verificationAddress" :failAddress="verificationFailAddress" :type=1 :pauseFrame="true"></facedialog>
    <!-- 授权弹窗 -->
    <el-dialog
      title="东堃用户隐私授权协议"
      :visible.sync="empowerDialog"
      width="860px"
      :close-on-click-modal="false"
      class="empower-dialog"
      :show-close="false"
    >
      <empower v-if="empowerDialog" v-model="isAgreeEmpower"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEmpower" size="small">取消</el-button>
        <el-button type="primary" @click="confirmEmpower" size="small">确定授权</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import TaskItem from './taskItem';
import Empower from '../components/empower'
export default {
  name: 'courseGoodsDetail',
  components: {
    TaskItem,
    Empower
  },
  data () {
    return {
          /**
           * 人脸识别
           */
          verification: false,
          verificationAddress: {}, // 人脸识别成功返回当前页面需要的地址参数
          verificationFailAddress: {},// 人脸识别失败返回当前页面需要的地址参数
          isFaceRecognize:null, // 是否需要人脸识别
          goodsKind:1,//1 普通商品 2 分销商品
          theme: '',
          confirmdialog:{
              show:true,
            },
          goodsId:parseInt(this.$route.query.goodsId),
          detailObj:{
              basePrice: 0,//划线价格
              days: 0,//购买多少天有效
              desc: "",//简介
              endTime: "",
              goodsType: 1,//商品引用类型1.训练营 2.培训任务
              id: 0,
              isFree: 0,//是否免费
              isOrder: 0,//是否已经购买 0 否 1 是
              name: "",//商品名称
              picUrl: "",
              purchaseNo: 0,//报名人数
              referId: 0,//商品引用ID
              salePrice: 0,//销售价格
              startTime: "",
              status: 1,//状态 0下架 1上架 2 待上架
              taskType: 1,//任务类型 1.必修课 2.选修课 3.公开课
              tryWatch: 1,//是否支持试看
              tryWatchList: [//可试看列表
                {
                  itemId: 0,
                  value: 0
                }
              ],
              validityType: 1//商品有效期类型（1:永久有效、2:期间、3:固定时间、4:购买后期间）
          },
          activeName:'first',
          catalogList:[//目录列表
          ],
          isFirst:true,//是否一级
          isTab:'true',
          isJoinPer:'true',
          quickLearnInfo:null,
          detailDataCompleted:false,
          empowerDialog:false,
          isAgreeEmpower:0, //isAgreeEmpower
          isDongkun:'false',
          isFreePriceChangeName: false,

    }
  },
  filters: {

  },
  created () {
    this.theme = localStorage.getItem('theme');
    this.goodsKind = this.$route.query.goodsKind //1 普通商品 2 分销商品
    this.activeName = this.theme === 'minzheng' ? 'second':'first'
    this.layoutType = this.$route.query.layoutType
    var configurationArr = JSON.parse(localStorage.getItem('configurationArr'));
      var isTab = configurationArr.filter((item)=>{
        return item.key == 'is_show_catalog_tab';
      })
      this.isTab = isTab[0].value;
      var isJoinPer = configurationArr.filter((item)=>{
        return item.key == 'is_show_commodity_number_buyer_detail_page';
      })
      this.isJoinPer = isJoinPer[0].value;
    var isdongkun = JSON.parse(localStorage.getItem('configurationArr')).filter((item)=>{
      return item.key == 'is_dongkun_empower';
    })
    this.isDongkun = isdongkun[0].value;
    if(this.isDongkun==='true'){
      this.viewUserAccess();
    }
    var isFreePriceChangeName = configurationArr.filter((item)=>{
        return item.key == 'is_free_price_change_name';
      })
      this.isFreePriceChangeName = isFreePriceChangeName[0].value;
      // console.log(this.isTab)
      // console.log(this.isJoinPer)
  },
  computed: {
    isInSignUpCycle(){
      if(this.detailObj.signUpEndTime){
        if((new Date() - new Date(this.detailObj.signUpEndTime)) > 0 || (new Date() - new Date(this.detailObj.signUpStartTime)) < 0) {

          return false
        } else {
          return true
        }
      }else{
        return true
      }
    },
      /**
       *
       */
    //课程类型
    type(){
      if(this.detailObj.taskType==3){
        return '公开课'
      }else if(this.detailObj.taskType==2){
        return '选修课'
      }else{
        return '必修课'
      }
    },
    //是否过期文案显示
    isOver(){
      if(this.detailObj.endTime&&this.detailObj.validityType==4){
        let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();
        let nowTime = new Date().getTime();
        if(lastTime>nowTime){
          return !this.quickLearnInfo ? '立即学习' : this.quickLearnInfo.firstStudy ? '立即学习':'继续学习';
        }else{
          return '已过期'
        }
      }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
        let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();
        let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime();
        let nowTime = new Date().getTime();
        if(lastTime>=nowTime&&startTime<=nowTime){
          return !this.quickLearnInfo ? '立即学习' : this.quickLearnInfo.firstStudy ? '立即学习':'继续学习';
        }else{
          if(startTime>=nowTime){
            return '未开始'
          }else{
            return '已过期'
          }
        }
      }else{
        return !this.quickLearnInfo ? '立即学习' : this.quickLearnInfo.firstStudy ? '立即学习':'继续学习';
      }
    },
    //是否过期
    isOverType(){
      if(this.detailObj.isOrder){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            return 'oks'
          }else{
            return 'no'
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime();
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
            return 'oks'
          }else{
            if(startTime>=nowTime){
              return 'no1'
            }else{
              return 'no2'
            }
          }
        }else if(this.detailObj.validityType==1){
          return 'oks'
        }else{
          return 'ok'
        }
      }else{
        return 'ok'
      }
    },
    //上架状态
    statusText(){
        if(this.detailObj.status==1){
          return '上架'
        }else if(this.detailObj.status==0){
          return '下架'
        }else{
          return '待上架'
        }
    },
    //有效期
      time(){
        if(this.detailObj.validityType==1){
          return "永久有效"
        }else if(this.detailObj.validityType==2){
          let sarr = this.detailObj.startTime.split(' ');
          let stimeArr = sarr[0].split('-');
          stimeArr[0] = stimeArr[0]+'年';
          stimeArr[1] = stimeArr[1]+'月';
          stimeArr[2] = stimeArr[2]+'日';
          let snewTime = stimeArr.join('');
          let earr = this.detailObj.endTime.split(' ');
          let etimeArr = earr[0].split('-');
          etimeArr[0] = etimeArr[0]+'年';
          etimeArr[1] = etimeArr[1]+'月';
          etimeArr[2] = etimeArr[2]+'日';
          let enewTime = etimeArr.join('');
          return snewTime+"-"+enewTime
        }else if(this.detailObj.validityType==3){
          if(this.detailObj.startTime&&this.detailObj.endTime){
            let sarr = this.detailObj.startTime.split(' ');
            let stimeArr = sarr[0].split('-');
            stimeArr[0] = stimeArr[0]+'年';
            stimeArr[1] = stimeArr[1]+'月';
            stimeArr[2] = stimeArr[2]+'日';
            let snewTime = stimeArr.join('');
            let earr = this.detailObj.endTime.split(' ');
            let etimeArr = earr[0].split('-');
            etimeArr[0] = etimeArr[0]+'年';
            etimeArr[1] = etimeArr[1]+'月';
            etimeArr[2] = etimeArr[2]+'日';
            let enewTime = etimeArr.join('');
            return snewTime+"-"+enewTime
          }else{
            let earr = this.detailObj.endTime.split(' ');
            let etimeArr = earr[0].split('-');
            etimeArr[0] = etimeArr[0]+'年';
            etimeArr[1] = etimeArr[1]+'月';
            etimeArr[2] = etimeArr[2]+'日';
            let enewTime = etimeArr.join('');
            return "至"+enewTime
          }
        }else{
          return "报名后"+this.detailObj.days+"天内有效"
        }
      }
  },
   mounted() {
     this.getGoodsDetail(this.goodsKind);//1 普通商品 2 分销商品
    // this.getList();
    if (window.history && window.history.pushState) {
            // 往历史记录里面添加一条新的当前页面的url
            history.pushState(null, null, document.URL);
            // 给 popstate 绑定一个方法 监听页面刷新
            window.addEventListener("popstate", this.back, false); //false阻止默认事件
        }
  },

  beforeDestroy() {
    window.removeEventListener("popstate", this.back, false); //false阻止默认事件
  },
  methods: {
    setFreeString() {
      return this.isFreePriceChangeName && this.isFreePriceChangeName == 'true' ? '招生中' : '免费'
    },
    formatDate(date) {
        return dayjs(date).format('YYYY.MM.DD')
      },
    //返回
      back(){
        this.$router.go(-1);
      },
      isToStudy(){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            this.toStudy()
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime();
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
              this.toStudy()
          }
        }else{
          this.toStudy()
        }

      },
      /**
       * 查询培训任务规则
       */
      async findfaceNeed(taskId) {
          await this.$api.examination.findfaceNeed(taskId).then(res=>{
              this.isFaceRecognize = res.data.isCourseFaceRecognize;
          })
      },
    // 东堃查看用户是否同意协议
    viewUserAccess(){
      this.$api.courseGoodsDetail.viewUserAccess().then(res=>{
        if(res.code==0){
          this.isAgreeEmpower = res.data;
        }
      })
    },
    cancelEmpower(){
      this.isAgreeEmpower = 0;
      this.empowerDialog = false;
      this.$message({
        message:'授权失败，您暂时不能报名',
        type:'error',
        duration:2000
      });
    },
    // 是否显示授权
    confirmEmpower(){
        if(this.isAgreeEmpower===0){
          this.$message({
            message:'请勾选同意隐私协议后确认授权',
            type:'warning'
          });
        }else{
          this.$api.courseGoodsDetail.saveUserAccess().then(res=>{
            if(res.code==0){
              this.isAgreeEmpower = 1;
              this.$message({
                message:'授权成功，您现在可以报名了',
                type:'success',
              })
            }else{
              this.isAgreeEmpower = 0;
            }
            this.empowerDialog = false;
          })
        }
    },
    /**
     * quickLearn 快速学习 跳转课程播放页  bool:是否是点击操作
     */
     async quickLearn(type){
            if(this.detailObj.goodsType === 2 && type === 1){ // 培训任务
                await this.findfaceNeed(this.detailObj.referId)
            }
            if(!this.quickLearnInfo && type === 1){
                this.isToStudy();
                return false;
            }
            const params = {
                taskId:this.detailObj.referId,
                taskType:this.detailObj.goodsType
            }
           await this.$api.course.quickLearn({params}).then(res => {
                this.quickLearnInfo = res.data;
                if(res.data && type === 1){
                    /**
                     * 判断是否人脸识别  如果进度等于100 不需要人脸识别
                     */
                    if(this.isFaceRecognize && res.data.learnRate !== 100){
                        this.verification = true;
                        this.confirmdialog.show = true;
                        this.verificationAddress = {
                            path:'/course/play',
                            query:{
                                trainId:res.data.taskId,
                                courseId:res.data.courseId,
                                detailId:res.data.detailId,
                                studyProcess:res.data.learnRate,
                                courseName:res.data.courseName,
                                taskType:res.data.taskType,//2：任务；1：训练营
                                backPathName:res.data.taskType == 1 ? '/course/catalog' : '/learning/courseDetails'
                            }
                        }
                        this.verificationFailAddress = {
                            path:'/courseGoodsDetail',
                            query:{
                                goodsId:this.$route.query.goodsId,
                                goodsKind:this.$route.query.goodsKind
                            }
                        };
                    }else {
                        this.$router.push({
                            path:'/course/play',
                            query:{
                                trainId:res.data.taskId,
                                courseId:res.data.courseId,
                                detailId:res.data.detailId,
                                studyProcess:res.data.learnRate,
                                courseName:res.data.courseName,
                                taskType:res.data.taskType,//2：任务；1：训练营
                                backPathName:res.data.taskType == 1 ? '/course/catalog' : '/learning/taskDetail'
                            }
                        })
                    }

                }

            })

    },
    // 跳转任务大纲 训练营大纲
    toStudy(){
      if(this.detailObj.goodsType==1){
        this.$router.push({
          path:'/course/catalog',
          query:{
            taskId:this.detailObj.referId,//训练营id
            taskType:this.detailObj.goodsType,
            goodsId:this.$route.query.goodsId,
            backPath:this.$route.path,
            goodsKind:this.$route.query.goodsKind
          }
        })
      }else if(this.detailObj.goodsType==2){
        // this.$router.push(`/course/catalog?id=${this.detailObj.referId}&dymode=1`)
        this.$router.push({
          path:'/learning/taskDetail',
          query:{
            id:this.detailObj.referId,//培训任务id
            goodsId:this.$route.query.goodsId,
            backPath:this.$route.path,
            type:this.detailObj.goodsType,
            goodsKind:this.$route.query.goodsKind,
            isNeedBreadcrumb:this.$route.query.isNeedBreadcrumb
          }
        })
      }else if(this.detailObj.goodsType==3){
        this.$router.push({
          path:'/assess',
          query:{
            assessId:this.detailObj.referId,//测评id
            refId:this.$route.query.goodsId,
            refType:2,
            goodsId:this.$route.query.goodsId,
            pathName:this.$route.path,
            goodsKind:this.$route.query.goodsKind
          }
        })
      }
    },
    //获取商品基本信息
    getGoodsDetail(goodsKind){
     //1 普通商品 2 分销商品
      if(goodsKind == 2){
        this.getDistributionGoodsDetail()
      }else {
        this.getCommonGoodsDetail()
      }
    },
    // 获取普通商品详情
    async getCommonGoodsDetail(){
      await this.$api.courseGoodsDetail.findGoodsDetailById({ goodsId: this.goodsId }).then(res=>{
        if(res.code===0 && res.data ){
          this.detailDataCompleted = true;
          this.detailObj = res.data;
          console.log(this.detailObj.labelNameList)
          this.quickLearn();
          this.getList();
        }
      })
    },
    // 获取分销商品详情
    async getDistributionGoodsDetail(){
      await this.$api.openPlatform.findGoodsDetailById({ goodsId: this.goodsId }).then(res=>{
        if(res.code===0 && res.data){
          this.detailDataCompleted = true;
          this.detailObj = res.data;
          // 处理字段不一致问题
            //分销商品 0审核中；1已上架；2已下架；3审核不通过
            //普通商品 0下架 1上架 2 待上架
          this.detailObj.status = res.data.status == 2? 0:res.data.status
         this.quickLearn()

          this.getList();
        }
      })
    },
    //目录数据查询
    getList() {
      // console.log(this.detailObj.goodsType)
      if(this.detailObj.goodsType==1){
        //训练营目录
        this.$api.courseGoodsDetail.findTrainListById(
          {
            id: this.detailObj.referId,
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code===0){
              let tempArr = [];
                let endData = [];
                let timeArr = [];
                let num = [];
                res.data.menuList.forEach(tiem => {
                    tiem.childItemList = [];
                    num.push(tiem.lastStudyTime);
                    if (tiem.lastStudyTime !== null) {
                        timeArr.push(tiem.lastStudyTime);
                    }
                });
                this.timelength = timeArr.sort(function(a, b) {
                    return b > a;
                });
                res.data.menuList.forEach(item => {
                    tempArr.push(item.stageName);
                });
                let setName = [...new Set(tempArr)]; //.sort();
                setName.forEach((el, index) => {
                    let datalist = [];
                    res.data.menuList.forEach(items => {
                        if (el === items.stageName) {
                            items.stageName = items.label
                            datalist.push(items);
                        }
                    });
                    endData.push({
                        stageName: el,
                        id: index,
                        type:0,
                        childItemList: datalist,
                        sort:1
                    });
                });
                this.catalogList = endData;
                // console.log(this.catalogList)
            }else{
              console.log(res.data.message)
            }
        })
      }
      else if(this.detailObj.goodsType==2){
        //培训任务目录
        this.$api.courseGoodsDetail.findTaskListById1(
          {
            id: this.detailObj.referId,
            version:1//兼容接口版本
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code==0){
              this.catalogList = res.data.taskItemVoList;
            }
        })
      }

    },
    handleClick() {
      // this.dialogVisible=true;
      if(document.getElementsByTagName('video')[0]){
        document.getElementsByTagName('video')[0].pause()
      }
    },
    //立即报名
    toPay(){
      if(this.detailObj.signUpEndTime && this.detailObj.signUpStartTime) {
        if(new Date() - new Date(this.detailObj.signUpEndTime) > 0 ) {
          this.$message({
            message: '报名已过期',
            type: 'warning'

          })
          return
        } else if((new Date() - new Date(this.detailObj.signUpStartTime)) < 0){
          this.$message({
            message: '报名未开始',
            type: 'warning'
          })
          return
        }
      }
      if(localStorage.getItem('token')){
        if( this.isDongkun==='true' &&  this.isAgreeEmpower===0){ //东堃定制授权确认弹窗
            this.empowerDialog = true;
            return
          }
        var obj = {
          goodsId:this.goodsId,
          orderSource:1,
          orderType:this.goodsKind == 2? 4:null //4渠道分销(订单类型)
        }
          this.$api.courseGoodsDetail.order(obj).then(res=>{
            if(res.code==0){
              if(res.data.placeOrderSuccess && res.data.realPayPrice  > 0) {
                this.$router.push(`/orderPage?orderNo=${res.data.orderNo}&dymode=1`)
              } else if(res.data.placeOrderSuccess && res.data.realPayPrice  === 0){
                //
                this.$message({
                  message: '报名成功',
                  type: 'success'
                })
                this.detailObj.isOrder = 1;
              } else {
                if(res.data.url) {
                  window.open(res.data.url, '_self')
                } else {
                  this.$router.go(-1)
                }
              }
            }
          })
        // }



      }else{
        //未登录转登录
        this.$router.push({
            path: '/login',
            query: {
                name: 'courseGoodsDetail',
                goodsId: this.goodsId,
                goodsKind: this.goodsKind //1 普通商品 2 分销商品
                // type: 'order',
                // id: this.goodsId,
                // referId: this.detailObj.referId
            }
        })
      }
    }
  },

};
</script>

<style lang="stylus" scoped>
@import "../css/courseGoodsDetail.styl"
.empower-dialog{
  /deep/.el-dialog__body{
    max-height:420px;
    overflow:scroll;
  }
  /deep/.el-dialog__header{
    text-align:center;
  }
  /deep/.el-dialog__footer{
    text-align:center;
  }
  /deep/.el-button--primary{
    background:rgb(45, 103, 255);
  }
}
</style>
